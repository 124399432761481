<template>
  <nav class="navbar fixed-bottom navbar-light bg-light">
    <div class="container-fluid">
        <small class="text-muted text-center mx-auto">
          © {{ currentYear }} Malloc Data Inc.
          &nbsp;&nbsp;
          <router-link :to="{name: 'termOfUse' }">Terms of Use</router-link>
          &nbsp;&nbsp;
          <router-link :to="{name: 'privacyPolicy' }">Privacy Policy</router-link>
        </small>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'BottomNavBar',
  props: {
  },
  computed: {
    currentYear: function () {
      // `this` points to the vm instance
      return new Date().getFullYear();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
